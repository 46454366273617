<template>
  <div>
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-else class="ap-list_paddings ap-list">
      <div v-if="!archiveItems.length" class="ap-list_no-items">
        <img src="../../../assets/no-items.svg" />
        {{ $t("general.table.noItems") }}
      </div>
      <AppointmentsListItem
        v-for="encounter in archiveItems"
        :key="encounter.id"
        :item="encounter"
        :role="role"
        place="archive"
        @onButtonClick="joinRoom"
      />
    </div>
  </div>
</template>
<script>
import AppointmentsListItem from "@/components/AppointmentsListItem/index.vue";
import { archiveListMixin } from "@/mixins/archiveList.js";
import { RolesEnum } from "@/types/Roles.enum";

export default {
  components: {
    AppointmentsListItem,
  },
  data() {
    return {
      role: RolesEnum.Patient,
    };
  },
  mixins: [archiveListMixin],
};
</script>
